import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import getAxiosInstance from '../../axios-clicks';
import styles from './Invoice.module.css';
import invoiceTemplateStyles from './InvoiceTemplate.module.css';
import InvoiceTemplate from './InvoiceTemplate';
import InvoiceSummaryTemplate from './InvoiceSummaryTemplate';
import Spinner from '../../components/UI/Spinner/Spinner';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

pdfMake.vfs = pdfFonts.pdfMake.vfs;



class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            loading: true,
            error: false,
            
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
      }

  componentDidMount() {
    this.fetchStudentOrderInvoices();
  }

  fetchStudentOrderInvoices = () => {
    const { orderId } = this.props;
    this.axiosInstance.get(`/orders/facture/${orderId}`)
    .then(response => {
      // console.log(response.data);
      this.setState({ invoices: response.data, loading: false });
    })
    .catch(error => {
        this.setState({ error: true, loading: false });
    });
    
  };
 
 handleDownloadClick = (invoiceInfo) => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      alert('Please allow popups for this site.');
      return;
    }
    // printWindow.document.write('<h2>Loading...</h2>');
    printWindow.document.write('<div id="pdf-content"></div>');
    this.printInvoice(invoiceInfo, printWindow);
  };

  printInvoice = (invoiceInfo, printWindow) => {
    ReactDOM.render(<InvoiceTemplate invoiceInfo={invoiceInfo} />, printWindow.document.body);
    printWindow.document.title = 'Invoice';

  };

  handleDownloadSummaryClick = () => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      alert('Please allow popups for this site.');
      return;
    }
    printWindow.document.write('<h2>Loading...</h2>');
    this.printSummary(printWindow);
  };

  printSummary = (printWindow) => {
    ReactDOM.render(<InvoiceSummaryTemplate invoices={this.state.invoices} />, printWindow.document.body);
    printWindow.document.title = 'Invoice Summary';
  };

  render() {
    const { invoices, loading, error } = this.state;

    if (loading) return <Spinner/>;
    if (error) return <div>Error: {error}</div>;

    return (
      <div className={styles.invoicesComponent}>
        {invoices.map((invoice, index) => (
          <div key={invoice.id} className={styles.invoiceMessage}>
            <span>
              Télecharger le récapitulatif de l'élève {index + 1}
            </span>
            <button
              className={styles.downloadButton}
              onClick={() => this.handleDownloadClick(invoice)}
            >
              Télecharger
            </button>
          </div>
        ))}
        {invoices && (<div className={styles.invoiceSummaryMessage}>
          <span>Télecharger le récapitulatif</span>
          <button
            className={styles.downloadButton}
            onClick={this.handleDownloadSummaryClick}
          >
            Télecharger
          </button>
        </div>)}
      </div>
    );
  }
}

export default Invoice;
