import React, { Component } from 'react';
import classes from './Quotation.css';
import getAxiosInstance from '../../axios-clicks';
import { Helmet } from "react-helmet-async";
import ValidatedQuotation from './ValidatedQuotation/ValidatedQuotation';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import Spinner from '../../components/UI/Spinner/Spinner';
import Notification from '../../components/UI/Notification/Notification';

const BOOK_COVERING_PRICE = 5;
const ARTICLE_DE_BUREAU_MAX_QUANTITY = 15;
const COUVERTURE_POUR_LIVRE_MAX_QUANTITY = 100;
const LIVRE_MAX_QUANTITY = 3;
const COUVERTURE_ADHESIVE = "couverture adhesive pour livre";
const COUVERTURE_POLYETHYLENE = "couverture polyethylene pour livre";
const DELIVERY_FEES  = {
  'oneStudentOrder': 38,
  'twoStudentOrders': 58,
  'threeStudentOrdersOrMore': 68
};
class Quotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      selectedStudentIndex: null,
      savingState: false,
      showCommentsContent: false,
      purchase: false,
      adressForm: {
        adress: {
          elementType: 'input',
          elementConfig: {
            type: 'text',
            placeholder: 'Adresse'
          },
          value: "",
          validation: {
            required: true
          },
          valid: false,
          touched: false
        },

        city: {
          elementType: 'input',
          elementConfig: {
            type: 'text',
            placeholder: 'Ville'
          },
          value: '',
          validation: {
            required: true
          },
          valid: false,
          touched: false
        }
      },
      displayCheckout: false,
      formIsValid: false,
      loading: false,
      purchasing: false,
      errors: {
        networkError: false,
        validatingOrderError: false

      },
    };
    this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  componentDidMount() {
    this.fetchStudentOrders();

  }
  fetchStudentOrders = () => {
    const { orderId } = this.props;
    this.axiosInstance.get(`/orders/quotations/${orderId}`)
      .then(response => {
        // console.log(response.data);
        const students = response.data;
        const selectedStudentIndex = students.length === 1 ? 0 : null;
        const selectedStudent = students[selectedStudentIndex];
        const showCommentsContent = selectedStudent && selectedStudent.comments && selectedStudent.comments.old ? true : false;
        this.setState({ students, selectedStudentIndex, showCommentsContent });
      })
      .catch(error => {
        alert("Une erreur inconnue s'est produite, merci de contacter le service client")
      });

  };
  handleStudentSelection = (index) => {
    this.setState({ selectedStudentIndex: index });
  }
  handleQualitySelection = (quality) => {
    const { selectedStudentIndex, students } = this.state;
    const selectedStudent = students[selectedStudentIndex];
    const updatedStudent = {
      ...selectedStudent,
      chosenQuality: quality,
      fournitures: selectedStudent.fournitures.map((fourniture) => {
        let chosenArticleId = fourniture.chosenArticleId;

        if (fourniture.articles.length === 1) {
          // Only one article, use its ID
          chosenArticleId = fourniture.articles[0].id;
        } else {
          const matchingArticle = fourniture.articles.find((article) => article.quality === quality);
          if (matchingArticle) {
            chosenArticleId = matchingArticle.id;
          } else {
            // No article with the chosen quality, use the one with quality "classique"
            const defaultArticle = fourniture.articles.find((article) => article.quality === 'classique');
            if (defaultArticle) {
              chosenArticleId = defaultArticle.id;
            }
          }
        }

        return {
          ...fourniture,
          chosenArticleId,
        };
      }),
    };

    const updatedStudents = [...students];
    updatedStudents[selectedStudentIndex] = updatedStudent;
    this.setState({ students: updatedStudents });
  };

  handleIncreaseQuantity = (fournitureId) => {
    const { selectedStudentIndex, students } = this.state;
    const student = students[selectedStudentIndex];
    const fournitureIndex = student.fournitures.findIndex(fourniture => fourniture.id === fournitureId);
    const fourniture = student.fournitures[fournitureIndex];
  
    if (fourniture.matchType === 'Livre') {
      if (fourniture.quantity < LIVRE_MAX_QUANTITY) {
        const updatedFournitures = [...student.fournitures];
        updatedFournitures[fournitureIndex] = { ...fourniture, quantity: fourniture.quantity + 1 };
  
        if (student.coveringService) {
          const coveringIndex = updatedFournitures.findIndex(f => f.fourniture === COUVERTURE_POLYETHYLENE);
          if (coveringIndex > -1) {
            updatedFournitures[coveringIndex] = {
              ...updatedFournitures[coveringIndex],
              quantity: updatedFournitures[coveringIndex].quantity + 1
            };
          }
        } else {
          const coveringIndex = updatedFournitures.findIndex(f => f.fourniture === COUVERTURE_ADHESIVE);
          if (coveringIndex > -1) {
            updatedFournitures[coveringIndex] = {
              ...updatedFournitures[coveringIndex],
              quantity: updatedFournitures[coveringIndex].quantity + 1
            };
          }
        }
  
        const updatedStudent = { ...student, fournitures: updatedFournitures };
        const updatedStudents = [...students];
        updatedStudents[selectedStudentIndex] = updatedStudent;
        this.setState({ students: updatedStudents });
      } else {
        alert("Vous ne pouvez pas dépasser cette quantité");
      }
    } else {
      if (fourniture.quantity < ARTICLE_DE_BUREAU_MAX_QUANTITY
        || (fourniture.quantity < COUVERTURE_POUR_LIVRE_MAX_QUANTITY && (fourniture.fourniture === COUVERTURE_POLYETHYLENE || fourniture.fourniture === COUVERTURE_ADHESIVE))) {
        const updatedFournitures = [...student.fournitures];
        updatedFournitures[fournitureIndex] = { ...fourniture, quantity: fourniture.quantity + 1 };
        const updatedStudent = { ...student, fournitures: updatedFournitures };
        const updatedStudents = [...students];
        updatedStudents[selectedStudentIndex] = updatedStudent;
        this.setState({ students: updatedStudents });
      } else {
        alert("Vous ne pouvez pas dépasser cette quantité");
      }
    }
  };
  
  handleDecreaseQuantity = (fournitureId) => {
    const { selectedStudentIndex, students } = this.state;
    const student = students[selectedStudentIndex];
    const fournitureIndex = student.fournitures.findIndex(fourniture => fourniture.id === fournitureId);
    const fourniture = student.fournitures[fournitureIndex];
  
    const updateQuantity = (quantity) => {
      const updatedFournitures = [...student.fournitures];
      updatedFournitures[fournitureIndex] = { ...fourniture, quantity: quantity };
  
      if (fourniture.matchType === 'Livre') {
        if (student.coveringService) {
          const coveringIndex = updatedFournitures.findIndex(f => f.fourniture === COUVERTURE_POLYETHYLENE);
          if (coveringIndex > -1) {
            updatedFournitures[coveringIndex] = {
              ...updatedFournitures[coveringIndex],
              quantity: updatedFournitures[coveringIndex].quantity - 1
            };
          }
        } else {
          const coveringIndex = updatedFournitures.findIndex(f => f.fourniture === COUVERTURE_ADHESIVE);
          if (coveringIndex > -1) {
            updatedFournitures[coveringIndex] = {
              ...updatedFournitures[coveringIndex],
              quantity: updatedFournitures[coveringIndex].quantity - 1
            };
          }
        }
      }
  
      const updatedStudent = { ...student, fournitures: updatedFournitures };
      const updatedStudents = [...students];
      updatedStudents[selectedStudentIndex] = updatedStudent;
      this.setState({ students: updatedStudents });
    };
  
    if (fourniture.quantity > 1) {
      updateQuantity(fourniture.quantity - 1);
    } else if (fourniture.quantity === 1) {
      const confirmed = window.confirm('Etes-vous sur de vouloir diminuer la quantité de cet article à 0?');
      if (confirmed) {
        updateQuantity(0); // Set quantity to 0 instead of removing the item
      }
    }
  };
  
  

  handleCoveringServiceChange = (coveringService) => {
    const { selectedStudentIndex, students } = this.state;
    const student = students[selectedStudentIndex];

    if (!student || !student.fournitures) {
      return;
    }

    let booksNumber = student.fournitures
      .filter((fourniture) => fourniture.matchType === 'Livre')
      .reduce((total, fourniture) => total + fourniture.quantity, 0);

    const updatedFournitures = student.fournitures.map(fourniture => {
      if (coveringService) {
        if (fourniture.fourniture === COUVERTURE_ADHESIVE) {
          return { ...fourniture, quantity: 0 };
        } else if (fourniture.fourniture === COUVERTURE_POLYETHYLENE) {
          return { ...fourniture, quantity: booksNumber };
        }
      } else {
        if (fourniture.fourniture === COUVERTURE_ADHESIVE) {
          return { ...fourniture, quantity: booksNumber };
        } else if (fourniture.fourniture === COUVERTURE_POLYETHYLENE) {
          return { ...fourniture, quantity: 0 };
        }
      }
      return fourniture;
    });

    const updatedStudent = { ...student, fournitures: updatedFournitures, coveringService };
    const updatedStudents = [...students];
    updatedStudents[selectedStudentIndex] = updatedStudent;

    this.setState({ students: updatedStudents });
  };

  handleNewCommentChange = (event) => {
    const { selectedStudentIndex, students } = this.state;
    const student = students[selectedStudentIndex];
    const updatedComments = { ...student.comments, new: event.target.value };
    const updatedStudent = { ...student, comments: updatedComments };
    const updatedStudents = [...students];
    updatedStudents[selectedStudentIndex] = updatedStudent;
    this.setState({ students: updatedStudents });
  }
  handleNewQuotationRequest = () => {
    const { selectedStudentIndex, students } = this.state;
    const student = students[selectedStudentIndex];

    const quotationRequest = {
      comment: student.comments.new,
      coveringServicePrice: 0,
      studentOrderId: student.studentOrderId,
      chosenQuality: student.chosenQuality,
      fournitures: []
    };

    // Build fournitures list
    student.fournitures.forEach((fourniture) => {
      const { id, fourniture: fournitureName, quantity, match, matchType, articleReference, chosenArticleId } = fourniture;
      const article = fourniture.articles.find((a) => a.id === chosenArticleId);

      quotationRequest.fournitures.push({
        id,
        fourniture: fournitureName,
        quantity,
        match,
        matchType,
        articleReference,
        chosenArticleId,
        article_name: article.name,
        price: article.price
      });
    });

    // Send the POST request
    this.axiosInstance.post('/quotations/requestNewQuotation', quotationRequest)
      .then(response => {
        // console.log('New quotation requested:', response.data);
        const updatedStudents = [...students];
        updatedStudents[selectedStudentIndex].quotationStatus = "Devis en cours de modification";
        this.setState({ students: updatedStudents });
      })
      .catch(error => {
        console.error('Error to request new quotation:', error);
        // Handle error
      });

  };
  handleCommentTitleClick = () => {
    this.setState(prevState => ({
      showCommentsContent: !prevState.showCommentsContent,
    }));
  }

  downloadQuotation = (studentOrderId) => {
    fetch(`/api/downloadQuotation?studentId=${studentOrderId}`)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary URL object to generate a temporary download link
        const url = window.URL.createObjectURL(blob);

        // Create a new anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = "quotation.pdf"; // Set the desired filename

        // Programmatically click the anchor element to trigger the download
        a.click();

        // Cleanup by revoking the temporary URL object
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading quotation:", error);
      });
  };

  handleQuotationValidation = () => {
    const { students, selectedStudentIndex } = this.state;
    let allQuotationsAreValidated = true;
    for (let i = 0; i < students.length; i++) {
      if (i !== selectedStudentIndex && students[i].quotationStatus !== "Devis validé") {
        allQuotationsAreValidated = false;
        break;
      }
    }

    if (allQuotationsAreValidated) {
      this.setState({ displayCheckout: true }, () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    } else {
      this.sendQuotation();
    }
  };
  orderHandler = (event) => {
    event.preventDefault();
    this.sendQuotation();
  }
  sendQuotation = () => {
    this.setState({ loading: true });
    const { selectedStudentIndex, students, adressForm } = this.state;
    const student = students[selectedStudentIndex];

    // Calculate coveringServicePrice
    const booksNumber = student.fournitures
      .filter(fourniture => fourniture.matchType === 'Livre')
      .reduce((total, fourniture) => total + fourniture.quantity, 0);
    const coveringServicePrice = student.coveringService? booksNumber * BOOK_COVERING_PRICE : 0;

    let deliveryFees = 0;
  
    if (students.length === 1) {
      deliveryFees = DELIVERY_FEES['oneStudentOrder'];
    } else if (students.length === 2) {
      deliveryFees = DELIVERY_FEES['twoStudentOrders'];
    } else if (students.length >= 3) {
      deliveryFees = DELIVERY_FEES['threeStudentOrdersOrMore'];
    }
  


    const quotationRequest = {
      comment: student.comments.new,
      discount: student.discount,
      coveringServicePrice,
      studentOrderId: student.studentOrderId,
      chosenQuality: student.chosenQuality,
      fournitures: student.fournitures.filter(fourniture => fourniture.quantity > 0).map(fourniture => {
        const { id, fourniture: fournitureName, quantity, match, matchType, articleReference, chosenArticleId } = fourniture;
        const article = fourniture.articles.find(a => a.id === chosenArticleId);
        return {
          id,
          fourniture: fournitureName,
          quantity,
          match,
          matchType,
          articleReference,
          chosenArticleId,
          article_name: article.name,
          price: article.price,
          
        };
      }),
      orderAddress: adressForm.adress.value,
      orderCity: adressForm.city.value,
      deliveryFees
    };

    // Send the POST request
    this.axiosInstance.post('/quotations/validate', quotationRequest)
      .then(response => {
        const { selectedStudentIndex, students } = this.state;
        const selectedStudent = students[selectedStudentIndex];
        const allOtherQuotationsValidated = students
          .filter((_, index) => index !== selectedStudentIndex) // Exclude the selected student
          .every(student => student.quotationStatus === "Devis validé"); // Check if every other student's quotation is validated

          // Create the covering service fourniture
          const coveringServiceFourniture = {
            fourniture: "Service de couverture",
            quantity: booksNumber,
            price: BOOK_COVERING_PRICE,
            status: "being searched",
            textColor: "black",
            articles: [{
              id: 0,
              price: BOOK_COVERING_PRICE,
              chosenArticleId: 0
            }]
          };

        const updatedStudent = {
          ...selectedStudent,
          quotationStatus: "Devis validé",
          fournitures: selectedStudent.fournitures
            .filter(fourniture => fourniture.quantity > 0)
            .map(fourniture => {
              const article = fourniture.articles.find(a => a.id === fourniture.chosenArticleId);

              if (article) {
                return {
                  ...fourniture,
                  status: 'being searched',
                  articles: [article],
                };
              } else {
                // If no article is found, return the fourniture unmodified
                return fourniture;
              }
            }),
        };

        // Add covering service fourniture if it doesn't already exist
      const existingCoveringService = updatedStudent.fournitures.find(fourniture => fourniture.fourniture === "Service de couverture");
      if (!existingCoveringService && updatedStudent.coveringService) {
        updatedStudent.fournitures.push(coveringServiceFourniture);
      }

        const updatedStudents = [...students];
        updatedStudents[selectedStudentIndex] = updatedStudent;
        this.setState({ students: updatedStudents, loading: false, purchasing: allOtherQuotationsValidated });
      })
      .catch(error => {
        this.setState({ loading: false });
        let errorsStatus = { ...this.state.errors };
        if (error.response) {
          // Request made and server responded
          errorsStatus.validatingOrderError = true;
        } else if (error.request) {
          // The request was made but no response was received
          errorsStatus.validatingOrderError = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          errorsStatus.networkError = true;
        }
        this.setState({
          errors: errorsStatus
        });
      });
  }


  inputChangedHandler = (event, inputIdentifier) => {
    const updatedAdressForm = {
      ...this.state.adressForm
    };
    const updatedFormElement = {
      ...updatedAdressForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedAdressForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedAdressForm) {
      formIsValid = updatedAdressForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ adressForm: updatedAdressForm, formIsValid: formIsValid });
  }
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    return isValid;
  }

  purchaseDoneHandler = () => {
    this.setState({ purchasing: false, displayCheckout: false });
  }
  errorModalCancelHandler = () => {
    const updatedErrorsStatus = {
      ...this.state.errors
    };
    for (let key in this.state.errors) {
      updatedErrorsStatus[key] = false;
    }
    this.setState({ errors: updatedErrorsStatus });
  }
  changeQuantityDisabled(fournitureName) {
    return fournitureName === COUVERTURE_POLYETHYLENE;
  }
  renderErrorModal() {
    return (
      <Modal show={this.state.errors.networkError || this.state.errors.validatingOrderError}
        modalClosed={this.errorModalCancelHandler}>
        <div>
          {this.state.errors.networkError && <p style={{ color: 'red' }}>Erreur de réseau, veuillez réessayer <br />Si le problème persiste, prière de nous contacter</p>}
          {this.state.errors.validatingOrderError && <p style={{ color: 'red' }}>Erreur lors de la validation, veuillez réessayer <br />Si le problème persiste, prière de nous contacter</p>}
        </div>
        <div className={classes.ModalButton} >
          <Button btnType="Danger" clicked={this.errorModalCancelHandler}>OK</Button>
        </div>
      </Modal>
    );
  }
  renderAdressForm() {
    const formElementsArray = Object.keys(this.state.adressForm).map(key => ({
      id: key,
      config: this.state.adressForm[key]
    }));
    
    return (
      <form className={classes.AdressForm} >
        {formElementsArray.map(formElement => (
          <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangedHandler(event, formElement.id)} />
        ))}
        
      </form>
    );
  }
  renderDeliveryFees(){
    const { students } = this.state;
    let selectedOption = '';

    
    if (students.length === 1) {
      selectedOption = 'oneStudentOrder';
    } else if (students.length === 2) {
      selectedOption = 'twoStudentOrders';
    } else if (students.length >= 3) {
      selectedOption = 'threeStudentOrdersOrMore';
    }

    return (
      <div className={classes.DeliveryFeesInfo}>
      <h3 className={classes.DeliveryFeesTitle}>Livraison</h3>
      <div
        className={`${classes.DeliveryFeeOption} ${selectedOption === 'oneStudentOrder' ? classes.SelectedDeliveryFeeOption : ''}`}
      >
        <input
          className={classes.DeliveryFeesInput}
          type="radio"
          name="deliveryFee"
          checked={selectedOption === 'oneStudentOrder'}
          readOnly
        />
        <span className={classes.DeliveryFeeOptionLabel}>1 élève</span>
        <span className={classes.DeliveryFeeOptionPrice}>{DELIVERY_FEES.oneStudentOrder} MAD</span>
      </div>
      <div
        className={`${classes.DeliveryFeeOption} ${selectedOption === 'twoStudentOrders' ? classes.SelectedDeliveryFeeOption : ''}`}
      >
        <input
          className={classes.DeliveryFeesInput}
          type="radio"
          name="deliveryFee"
          checked={selectedOption === 'twoStudentOrders'}
          readOnly
        />
        <span className={classes.DeliveryFeeOptionLabel}>2 élèves</span>
        <span className={classes.DeliveryFeeOptionPrice}>{DELIVERY_FEES.twoStudentOrders} MAD</span>
      </div>
      <div
        className={`${classes.DeliveryFeeOption} ${selectedOption === 'threeStudentOrdersOrMore' ? classes.SelectedDeliveryFeeOption : ''}`}
      >
        <input
          className={classes.DeliveryFeesInput}
          type="radio"
          name="deliveryFee"
          checked={selectedOption === 'threeStudentOrdersOrMore'}
          readOnly
        />
        <span className={classes.DeliveryFeeOptionLabel}>3 élèves ou plus</span>
        <span className={classes.DeliveryFeeOptionPrice}>{DELIVERY_FEES.threeStudentOrdersOrMore} MAD</span>
      </div>
    </div>
    );

  }

  renderCheckoutSummary = () => {
    const { students } = this.state;
  
    const getTotalForStudent = (student) => {
      const booksNumber = student.fournitures
      .filter(fourniture => fourniture.matchType === 'Livre')
      .reduce((total, fourniture) => total + fourniture.quantity, 0);
      const coveringServicePrice = student.coveringService? booksNumber * BOOK_COVERING_PRICE : 0;
      return student.fournitures.reduce((total, fourniture) => {
        let price;

        if (fourniture.articles.length === 1) {
          // If there is only one article, use its price
          price = fourniture.articles[0].price;
        } else {
          // If there are multiple articles, use the price of the chosen article
          const chosenArticle = fourniture.articles.find(article => article.id === fourniture.chosenArticleId);
          price = chosenArticle ? chosenArticle.price : 0;
        }
        return total + price * fourniture.quantity;
      }, 0) + coveringServicePrice;
    };
  
    let selectedOption = '';
  
    if (students.length === 1) {
      selectedOption = 'oneStudentOrder';
    } else if (students.length === 2) {
      selectedOption = 'twoStudentOrders';
    } else if (students.length >= 3) {
      selectedOption = 'threeStudentOrdersOrMore';
    }
  
    const deliveryFees = DELIVERY_FEES[selectedOption];
    const totalForAllStudents = students.reduce((total, student) => total + getTotalForStudent(student), 0);
    const overallTotal = totalForAllStudents + deliveryFees;
  
    return (
      <div className={classes.CheckoutSummary}>
      <h2>Sommaire</h2>
      {students.map((student, index) => (
        <div key={index} className={classes.CheckoutStudentSummary}>
          <h3>Elève {index + 1}</h3>
          <p>{getTotalForStudent(student).toFixed(2)} MAD</p>
        </div>
      ))}
      <div className={classes.CheckoutDeliveryFees}>
        <span>Livraison:</span>
        <p>{deliveryFees.toFixed(2)} MAD</p>
      </div>
      <div className={classes.CheckoutOverallTotal}>
        <span>Total:</span>
        <h3>{overallTotal.toFixed(2)} MAD</h3>
      </div>
    </div>
      
    );
  };
  renderCheckoutButton(){
    return(
      <div className={classes.CheckoutButtonContainer}>
        <button className={classes.CheckoutButton} disabled={!this.state.formIsValid} onClick={this.orderHandler}>Valider</button>
      </div>
    
  );
    
  }
  renderCheckout() {
    if (!this.state.displayCheckout) return null;

    return (
      <div>
        {this.renderAdressForm()}
        {this.renderDeliveryFees()}
        {this.renderCheckoutSummary()}
        {this.renderCheckoutButton()}
      </div>
    );
  }
  renderStudentTabs() {
    const { students, selectedStudentIndex } = this.state;
    return (
      <ul className={classes.ScrollableStudentsMenu}>
        {students.map((student, index) => (
          <li
            key={student.studentOrderId}
            className={`${classes.ScrollableStudentsMenuItem} ${selectedStudentIndex === index ? classes.ActiveStudentItem : ""}`}
            onClick={() => this.handleStudentSelection(index)}
          >
            Élève {index + 1}
          </li>
        ))}
      </ul>
    );
  }
  renderValidatedQuotation(fournitures) {
    return <ValidatedQuotation fournitures={fournitures} />;
  }

  renderQuotationStatus(selectedStudent, allQuotationsValidated) {
    return (
      <>
        <p>{selectedStudent.quotationStatus}</p>
        {!allQuotationsValidated && (
          <Notification message="Veuillez noter que le rassemblement des commandes ne commence que lorsque tous les devis sont validés." />
        )}
        {selectedStudent.quotationStatus === "Devis validé" && this.renderValidatedQuotation(selectedStudent.fournitures)}
      </>
    );
  }

  renderQualities(uniqueQualities, selectedStudent) {
    return (
      <div className={classes.Quality}>
        <label className={classes.TitleLabel}>Gamme</label>
        <div className={classes.QualityButtonContainer}>
          {uniqueQualities.map((quality) => (
            <button
              key={quality}
              onClick={() => this.handleQualitySelection(quality)}
              className={`${classes.QualityButton} ${selectedStudent.chosenQuality === quality ? classes.ActiveQualityButton : ""}`}
            >
              {quality}
            </button>
          ))}
        </div>
      </div>
    );
  }

  renderQuotationItems(selectedStudent, totalPrice, discountAmount) {
    return (
      <table className={classes.QuotationItemsTable}>
        <tbody>
          {selectedStudent.fournitures
            .filter(fourniture => fourniture.quantity > 0)
            .map((fourniture, index) => {
              const selectedArticle = fourniture.articles.find((article) => article.id === fourniture.chosenArticleId);
              const displayContent = selectedArticle.name.substring(0, 150);

              totalPrice += selectedArticle.price * fourniture.quantity;

              return (
                <React.Fragment key={fourniture.id}>
                  <tr>
                    <td className={classes.ItemContainer}>
                      <div className={classes.Quantity}>{fourniture.quantity}x</div>
                      <div className={classes.Content}>{displayContent}</div>
                      <div className={classes.Price}>{selectedArticle.price * fourniture.quantity}MAD</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.ButtonContainer}>
                      <div className={classes.Buttons}>
                        <button disabled={this.changeQuantityDisabled(fourniture.fourniture)} className={`${classes.CircleButton} ${classes.MinusButton}`} onClick={() => this.handleDecreaseQuantity(fourniture.id)}>
                          -
                        </button>
                        <button disabled={this.changeQuantityDisabled(fourniture.fourniture)} className={`${classes.CircleButton} ${classes.PlusButton}`} onClick={() => this.handleIncreaseQuantity(fourniture.id)}>
                          +
                        </button>
                      </div>
                    </td>
                  </tr>
                  {index !== selectedStudent.fournitures.length - 1 && (
                    <tr className={classes.HorizontalLine}>
                      <td colSpan="2"></td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    );
  }

  renderCoveringService(booksNumber, coveringService) {
    if (booksNumber <= 0) return null;

    return (
      <div className={classes.CoveringService}>
        <label className={classes.TitleLabel}>Service de couverture</label>
        <div className={classes.CoveringServiceButtons}>
          <button
            onClick={() => this.handleCoveringServiceChange(false)}
            className={`${classes.CoveringServiceButton} ${coveringService ? "" : classes.ActiveCoveringServiceButton}`}
          >
            Non
          </button>
          <button
            onClick={() => this.handleCoveringServiceChange(true)}
            className={`${classes.CoveringServiceButton} ${coveringService ? classes.ActiveCoveringServiceButton : ""}`}
          >
            Oui
          </button>
        </div>
      </div>
    );
  }

  renderSummary(totalPrice, discountAmount, coveringServicePrice) {
    return (
      <div className={classes.Summary}>
        <h3 className={classes.TitleLabel}>Récapitulatif</h3>
        <table className={classes.SummaryTable}>
          <tfoot>
            {coveringServicePrice > 0 && (
              <tr>
                <td colSpan={3}>Total des articles:</td>
                <td className={classes.Price}>{(totalPrice + discountAmount).toFixed(2)} MAD</td>
              </tr>
            )}
            {discountAmount > 0 && (
              <tr>
                <td colSpan={3}>Discount:</td>
                <td className={classes.Price}>{discountAmount.toFixed(2)} MAD</td>
              </tr>
            )}
            {coveringServicePrice > 0 && (
              <tr>
                <td colSpan={3}>Service de couverture:</td>
                <td className={classes.Price}>{coveringServicePrice.toFixed(2)} MAD</td>
              </tr>
            )}
            <tr className={classes.BoldRow}>
              <td colSpan={3}>Total:</td>
              <td className={classes.Price}>{(coveringServicePrice ? totalPrice + coveringServicePrice : totalPrice).toFixed(2)} MAD</td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }

  renderComments(comments) {
    return comments.length > 0 ? (
      <div className={classes.Comments}>
        <h3 className={classes.CommentTitle} onClick={() => this.handleCommentTitleClick()}>
          Remarques
          <span className={this.state.showCommentsContent ? classes.ArrowDown : classes.ArrowRight}></span>
        </h3>
        {this.state.showCommentsContent && (
          <div>
            <ul className={classes.CommentList}>
              {comments.map((comment, index) => {
                const commenter = Object.keys(comment)[0];
                const message = Object.values(comment)[0];
                const previousCommenter = index > 0 ? Object.keys(comments[index - 1])[0] : null;
                const isYou = commenter === "You";
                const commentContainerClass = isYou ? classes.CommentContainerYou : classes.CommentContainerLivrairie;
                const commentAlignClass = isYou ? classes.CommentAlignLeft : classes.CommentAlignLeft;
                const commenterClass = isYou ? classes.CommenterYou : classes.CommenterLivrairie;

                return (
                  <li key={index}>
                    {commenter !== previousCommenter && (
                      <div className={`${classes.CommenterLabel} ${commentAlignClass}`}>
                        <span className={commenterClass}>{commenter}</span>
                      </div>
                    )}
                    <div className={`${commentContainerClass} ${commentAlignClass}`}>{message}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    ) : null;
  }
  renderActionButtons(selectedStudent, savingState) {
    return (
      <div className={classes.SubmitButtons}>
        {savingState && <p>State saved!</p>}
        {selectedStudent.comments.new ? (
          <button
            onClick={this.handleNewQuotationRequest}
            className={classes.RequestQuotationButton}
          >
            Nouveau devis
          </button>
        ) : (
          <button
            onClick={this.handleQuotationValidation}
            className={classes.ValidateButton}
          >
            Commander
          </button>
        )}
      </div>
    );
  }
  renderQuotation(selectedStudent, totalPrice, discountAmount, coveringService, coveringServicePrice, comments) {
    const uniqueQualities = Array.from(
      new Set(
        selectedStudent.fournitures.flatMap((fourniture) =>
          fourniture.articles
            .filter((article) => article.quality)
            .map((article) => article.quality)
        )
      )
    ).sort((a, b) => {
      const order = ["economique", "classique", "premium"];
      return order.indexOf(a) - order.indexOf(b);
    });

    return (
      <div>
        {uniqueQualities.length > 1 && this.renderQualities(uniqueQualities, selectedStudent)}
        {this.renderQuotationItems(selectedStudent, totalPrice, discountAmount)}
        {selectedStudent.fournitures.length === 0 && <p>No articles found for this student.</p>}
        {this.renderCoveringService(selectedStudent.fournitures.filter(f => f.matchType === 'Livre').reduce((total, fourniture) => total + fourniture.quantity, 0), coveringService)}
        {this.renderSummary(totalPrice, discountAmount, coveringServicePrice)}
        {this.renderComments(comments)}
        <label>
          <textarea
            value={selectedStudent.comments.new || ""}
            className={classes.NewComment}
            placeholder="Ecrivez un commentaire ..."
            onChange={this.handleNewCommentChange} />
        </label>
        {this.renderActionButtons(selectedStudent, this.state.savingState)}
      </div>
    );
  }

  render() {
    const { students, selectedStudentIndex, savingState, displayCheckout, loading } = this.state;
    const selectedStudent = students[selectedStudentIndex];
    const allQuotationsValidated = students.every(student => student.quotationStatus === "Devis validé");
    const comments = selectedStudent?.comments?.old || [];

    let totalPrice = 0;
    let discountAmount = 0;
    let booksNumber = 0;
    let coveringService = selectedStudent?.coveringService;

    if (selectedStudent?.fournitures) {
      booksNumber = selectedStudent.fournitures
        .filter((fourniture) => fourniture.matchType === 'Livre')
        .reduce((total, fourniture) => total + fourniture.quantity, 0);

      totalPrice = selectedStudent.fournitures.reduce((total, fourniture) => {
        const article = fourniture.articles.find((a) => a.id === fourniture.chosenArticleId);
        if (article) {
          return total + (fourniture.quantity * article.price);
        }
        return total;
      }, 0);
    }

    let coveringServicePrice = coveringService ? BOOK_COVERING_PRICE * booksNumber : 0;

    return (
      <div>
        <Helmet>
          <title>Fournitures Scolaires - Devis</title>
          <meta
            name="description"
            content="Voici votre devis, vous pouvez le modifier et le valider pour qu'on commence à rassembler votre commande."
          />
        </Helmet>
        {this.renderErrorModal()}
        {loading ? (
          <Spinner />
        ) : displayCheckout ? (
          <div className={classes.Checkout}>
            <Modal show={this.state.purchasing}
              modalClosed={this.purchaseDoneHandler}>
              <p className={classes.ModalTitleSuccess}>Commande validée ! <br /></p>
              <p>Merci pour votre confiance <br />
                Votre commande va être traitée dans les plus brefs délais
              </p>
              <div className={classes.ModalButton}>
                <Button btnType="Success" clicked={this.purchaseDoneHandler}>OK</Button>
              </div>
            </Modal>
            {this.renderCheckout()}
          </div>
        ) : (
          <div>
            {this.renderStudentTabs()}
            {selectedStudent && (
              <div className={classes.StudentQuotation}>
                {selectedStudent.quotationStatus === "Devis validé" && this.renderQuotationStatus(selectedStudent, allQuotationsValidated)}
                {selectedStudent.quotationStatus !== "Devis disponible" && selectedStudent.quotationStatus !== "Devis validé" && (
                  <div>
                    <p>{selectedStudent.quotationStatus}</p>
                  </div>
                )}
                {/* {selectedStudent.quotationStatus === "Devis disponible" && this.renderQuotation(selectedStudent, totalPrice, discountAmount, coveringService, coveringServicePrice, comments)} */}
                {selectedStudent.quotationStatus === "Devis disponible" && <p>Nous avons malheureusement arreté le traitement des commandes pour cette année</p>}
                </div>
            )}
          </div>
        )}
      </div>
    );
  }

}

export default Quotation;