import React, { Component } from 'react';
import classes from './Footer.css';
import LogoWhite from '../../assets/images/LivrairieLogoWhite.png';
import Aux from '../../hoc/Auxil'


class Footer extends Component {
    render() {
        return (<div className={classes.Footer}>
                <img className={classes.Logo} src={LogoWhite} />

            </div>
            

        );
    }
}

export default Footer;