import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './ValidatedQuotation.module.css';

const ValidatedQuotation = ({ fournitures }) => {

  const [showTooltip, setShowTooltip] = useState(false);

  const getStyleForTextColor = (textColor) => {
    switch (textColor) {
      case 'red':
        return `${styles.redText} ${styles.strikethrough}`;
      case 'green':
        return styles.greenText;
      case 'black':
        return styles.blackText;
      default:
        return '';
    }
  };

  const getStatusIcon = (status, textColor) => {
    if (textColor === 'red') return null;
    switch (status) {
      case 'found':
        return <FontAwesomeIcon icon={faCheckCircle} className={styles.greenIcon} />;
      case 'being searched':
        return <FontAwesomeIcon icon={faSpinner} className={`${styles.blueIcon} ${styles.spinner}`} />;
      case 'not found':
        return <FontAwesomeIcon icon={faTimesCircle} className={styles.redIcon} />;
      default:
        return null;
    }
  };

  const totalPrice = fournitures
  .filter(fourniture => fourniture.status !== 'not found' && fourniture.textColor !== 'red')
  .reduce((total, fourniture) => {
    const article = fourniture.articles[0]; 
    return total + article.price * fourniture.quantity;
  }, 0);
  return (
    <div className={styles.quotation}>
      <div className={styles.iconDescription}>
        <span><FontAwesomeIcon icon={faCheckCircle} className={styles.greenIcon} /> Found</span>
        <span className={styles.infoContainer}>
          <FontAwesomeIcon icon={faSpinner} className={`${styles.blueIcon} ${styles.spinner}`} /> Being Searched
          <FontAwesomeIcon icon={faInfoCircle} className={styles.infoIcon} />
          <span className={`${styles.tooltip} ${showTooltip ? styles.visible : ''}`}>Nous effectuons la recherche auprès de 34 Autres librairies</span>
        </span>
        <span><FontAwesomeIcon icon={faTimesCircle} className={styles.redIcon} /> Not Found</span>
      </div>
      {fournitures.map((fourniture, index) => {
        const article = fourniture.articles[0]; 
        const textColorStyle = getStyleForTextColor(fourniture.textColor);
        const statusIcon = getStatusIcon(fourniture.status, fourniture.textColor)
        const price = fourniture.status === 'not found' ? 0.00 : article.price * fourniture.quantity;;

        return (
          <div key={index} className={`${styles.quotationItem} ${textColorStyle}`}>
            <span className={styles.icon}>{statusIcon}</span>
            <span className={styles.quantity}>{fourniture.quantity}</span>
            <span className={styles.name}>{fourniture.fourniture}</span>
            <span className={styles.price}>{price.toFixed(2)} MAD</span>
          </div>
        );
      })}
      <div className={styles.totalPrice}>
        Total des Articles: {totalPrice.toFixed(2)} MAD
      </div>
    </div>
  );
};

export default ValidatedQuotation;
