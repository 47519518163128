import React, { Component } from 'react';
import classes from './Features.css';

class Features extends Component {
    render() {
        return (
                <div className={classes.Features}>
                    <div className={classes.Feature}>
                        <div className={classes.Icon}>
                            <div className={classes.hexagon1}></div>
                            <div className={classes.hexagon2}></div>
                            <div className={classes.hexagon3}></div>
                        </div>
                        <h2>LIVRAISON</h2>
                        <p className={classes.Parag}>   Ne vous souciez de rien ! <br/>Nous veillons à ce que vos fournitures vous soient livrées jusqu’à votre porte dans les meilleurs délais</p>
                    </div>
                    <div className={classes.Feature}>
                    <div className={classes.Icon}>
                            <div className={classes.trapezoid}></div>
                            
                        </div>
                        <h2>PRIX</h2>
                        <p className={classes.Parag}> Le prix est le même que celui de la librairie !<br/>
                        Vous aurez le choix entre deux gammes: classique et Haute
                         </p>
                    
                    </div>
                    <div className={classes.Feature}>
                    <div className={classes.Icon}>
                            <div className={classes.circle}></div>
                            
                        </div>
                        <h2>SATISFAIT OU REMBOURSE</h2>
                        <p className={classes.Parag}> Notre équipe veille scrupuleusement au moindre détail de votre commande, tout en étant à votre écoute !<br/>
En cas de problème avec votre commande, dites nous ce qui ne va pas, nous ferons notre possible pour y remédier, ou faites vous entièrement rembourser en retournant le colis</p>
                    
                    </div>
                    
                </div>


        );
    }
}

export default Features;