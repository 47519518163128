import React from 'react';
import styles from './Notification.module.css'; // This imports the CSS module

const Notification = ({ message }) => {
  return (
    <div className={styles.notification}>
      <span className={styles.icon}>⚠️</span> {/* Warning Emoji as Icon */}
      <p className={styles.message}>{message}</p>
    </div>
  );
};

export default Notification;