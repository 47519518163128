import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import styles from './Guarantee.module.css';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import Spinner from '../../components/UI/Spinner/Spinner';

class Guarantee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guaranteePaymentDetails: null,
      loading: true,
      error: false,
      showModal: false,
      modalContent: '',
      confirmationType: '',
      confirmationMessage: '',
    };
    this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  componentDidMount() {
    this.fetchGuaranteePaymentDetails();
  }

  fetchGuaranteePaymentDetails = () => {
    const { orderId } = this.props;
    this.axiosInstance.get(`/orders/guarantee/${orderId}`)
      .then(response => {
        this.setState({ guaranteePaymentDetails: response.data, loading: false });
      })
      .catch(error => {
        this.setState({ error: true, loading: false });
      });
  };

  openModal = (type) => {
    const content = type === 'pay' 
      ? 'Confirmez-vous que vous acceptez la garantie?' 
      : 'Confirmez-vous que vous n\'acceptez pas de payer la garantie?';
    this.setState({ showModal: true, modalContent: content, confirmationType: type });
  };

  closeModal = () => {
    this.setState({ showModal: false, modalContent: '', confirmationType: '' });
  };

  handleConfirm = async () => {
    const { confirmationType, guaranteePaymentDetails} = this.state;
    this.setState({ loading: true });
    this.axiosInstance.post(`/guarantee/${confirmationType}Guarantee`, { orderId: guaranteePaymentDetails.orderId })
      .then(response => {
        this.setState({ confirmationMessage: 'Confirmation successful', loading: false });
      })
      .catch(error => {
        this.setState({ confirmationMessage: 'Confirmation failed', loading: false });
      });
  };

  render() {
    const { guaranteePaymentDetails, loading, error, showModal, modalContent, confirmationMessage } = this.state;

    if (loading) {
      return <Spinner />;
    }

    if (error || !guaranteePaymentDetails) {
      return <div className={styles.error}>Nothing found</div>;
    }

    const foundItems = new Set();
    const notFoundItems = new Set();

    guaranteePaymentDetails.studentOrders.forEach((studentOrder) => {
      studentOrder.studentOrderIndividualItems.forEach((item) => {
        if (item.status === 'In Box') {
          foundItems.add(item.idStudentOrderItem);
        } else if (item.status === 'Not Found') {
          notFoundItems.add(item.idStudentOrderItem);
        }
      });
    });

    const foundItemsSize = foundItems.size;
    const notFoundItemsSize = notFoundItems.size;
    const guaranteePercentage = guaranteePaymentDetails.guaranteePercentage;

    return (
      <div className={styles.container}>
        {confirmationMessage && <div className={styles.message}>{confirmationMessage}</div>}
        <p className={styles.description}>
          Nous avons trouvé {foundItemsSize} articles, et il reste {notFoundItemsSize} articles. Si vous souhaitez qu'on continue la recherche des articles manquants
          après la première livraison, il faudra payer une garantie de {guaranteePercentage * 100}% de la valeur des articles manquants. <br />
          Cette valeur s'ajoutera à la facture lors de la première livraison.
          Veuillez choisir <strong>Accepter la garantie</strong> pour continuer les recherches ou bien <strong>Refuser la garantie</strong> pour arrêter les recherches. <br />
          <strong>NB :</strong> Nous effectuons des recherches auprès de plus de 30 librairies partenaires.
        </p>
        {guaranteePaymentDetails.studentOrders.map((studentOrder, index) => {
          const notFoundStudentOrderItems = studentOrder.studentOrderIndividualItems.filter(
            (item) => item.status === 'Not Found'
          );

          if (notFoundStudentOrderItems.length === 0) return null;

          const distinctItems = [
            ...new Set(notFoundStudentOrderItems.map((item) => item.idStudentOrderItem)),
          ];

          return (
            <div key={studentOrder.id} className={styles.studentOrder}>
              <h2>Récapitulatif des articles non trouvés :</h2>
              <h3>Elève {index + 1}</h3>
              <ul>
                {distinctItems.map((id) => {
                  const item = notFoundStudentOrderItems.find(
                    (item) => item.idStudentOrderItem === id
                  );
                  return <li key={id}>{item.articleName}</li>;
                })}
              </ul>
            </div>
          );
        })}
        <div className={styles.buttons}>
          <button onClick={() => this.openModal('pay')} className={`${styles.button} ${styles.payButton}`}>
            Accepter la garantie
          </button>
          <button onClick={() => this.openModal('notPay')} className={`${styles.button} ${styles.notPayButton}`}>
            Refuser la garantie
          </button>
        </div>
        <Modal show={showModal} modalClosed={this.closeModal}>
          <div className={styles.modalContent}>
            <p>{modalContent}</p>
            <div className={styles.modalButtons}>
              <Button btnType="Success" clicked={this.handleConfirm}>Oui</Button>
              <Button btnType="Danger" clicked={this.closeModal}>Annuler</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Guarantee;
