import React from 'react';
import  classes from './Toolbar.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

const toolbar = (props) => (
    <header className={classes.Toolbar}>
        <DrawerToggle clicked={props.drawerToggleClicked}/>
        <div className={classes.InfoItems}><i><FontAwesomeIcon  icon={faPhone} /></i><a  href="tel:+212654950970">212654950970</a></div>
        <div className={classes.Logo}>
            <Logo />   
        </div> 
        
        {/* <NavLink
            to="/chausette-intelligente">
            <button className={classes.Btn}>Commander maintenant</button>
        </NavLink> */}
        <nav className={classes.DesktopOnly}>
            <NavigationItems/>
        </nav>
    </header>
        
);

export default toolbar;