import React from 'react';
import LivrairieLogo from '../../assets/images/LivrairieLogo2.png';
import LivrairieLogoText from '../../assets/images/LivrairieLogoText.png';
import classes from './Logo.css';

const logo = (props) => (
    <div className={classes.Logo} style={{height: props.height}}>
        <img className={classes.LivrairieLogo} src={LivrairieLogo} alt="LivrairieLogo" />
        <img className={classes.LivrairieLogoText} src={LivrairieLogoText} alt="LivrairieLogoText" />
        
    </div>
);

export default logo;