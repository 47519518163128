import React, { Component } from 'react';
import classes from './Promotion.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faTags, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';

class Promotion extends Component {
    render() {
        return (
            <div className={classes.Promotions}>
                <div className={classes.Prmotion}>
                    <div className={classes.Icon}>
                        <FontAwesomeIcon icon={faBookOpen} color='white' size="2x" />
                    </div>
                    <p className={classes.Parag}>Service de couverture</p>
                </div>

                <div className={classes.Prmotion}>
                    <div className={classes.Icon}>
                        <FontAwesomeIcon icon={faTags} size="2x" />
                    </div>
                    <p className={classes.Parag}>Même prix que la librairie</p>
                </div>

                <div className={classes.Prmotion}>
                    <div className={classes.Icon}>
                        <FontAwesomeIcon icon={faMoneyBillWave} size="2x" />
                    </div>
                    <p className={classes.Parag}>Paiement à la livraison</p>
                </div>
            </div>


        );
    }
}

export default Promotion;