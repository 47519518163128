import React, { Component } from 'react';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import classes from './SuppliesListUpload.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import getAxiosInstance from '../../axios-clicks';
import Input from '../../components/UI/Input/Input';
import Aux from '../../hoc/Auxil';
import Modal from '../../components/UI/Modal/Modal';

const initialState = {
  files: null,

  filesIsValid: false,
  orderForm: {
    customerFullName: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Nom & Prénom'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    customerPhoneNumber: {
      elementType: 'input',
      elementConfig: {
        type: 'tel',
        placeholder: 'Téléphone (06XXXXXXXX)',
        pattern: "[0-9]{10}"
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    comment: {
      elementType: 'textarea',
      elementConfig: {
        type: 'text',
        placeholder: 'Ecrivez un commentaire (Optionnel)',
        maxLength: '1000'
      },
      value: '',
      validation: {
        required: false
      },
      valid: true,
      touched: false
    }
  },
  formIsValid: false,
  loading: false,
  loadingPercentage: 0,
  purchasing: false,
  errors: {
    fileSizeError: false,
    fileExstensionError: false,
    maxFilesError: false,
    networkError: false,
    sendingOrderError: false,
    whatsappPhoneNumberError : false
  },


};
const imagesFormats = ["image/png", "image/jpeg", "image/jpg"];
const MaxSize = 6;
const MaxFiles = 14;

class SuppliesListUpload extends Component {
  state = {}
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
    let newState = { ...initialState };
    this.state = newState;
    this.scrollSpinnerRef = React.createRef();
    this.axiosInstance = getAxiosInstance(this.props.sandbox);

  }
  reset() {
    this.setState(initialState);
  }

  orderHandler = (event) => {
    event.preventDefault();
    if (this.state.files) {
      this.setState({ loading: true });

      const formData = new FormData();
      for (let formElementIdentifier in this.state.orderForm) {
        formData.append(formElementIdentifier, this.state.orderForm[formElementIdentifier].value)
      }

      for (let i = 0; i < this.state.files.length; i++) {

        formData.append('files', this.state.files[i]);
      }

      this.axiosInstance.post("orders/storeOrder", formData, {
        onUploadProgress: progressEvent => {
          this.setState({ loadingPercentage: Math.round(progressEvent.loaded / progressEvent.total * 100) });
        }
      })
        .then(response => {
          this.setState({
            loading: false
            , purchasing: true
          });
          window.fbq('track', 'Purchase');
        })
        .catch(error => {
          this.setState({ loading: false });
          let errorsStatus = { ...this.state.errors };
          if (error.response) {
            // Request made and server responded

            if (error.response.data.message.includes("Invalid Number")) {
              errorsStatus.whatsappPhoneNumberError = true;
            }
            else {
              errorsStatus.sendingOrderError = true;
            }
          } else if (error.request) {
            // The request was made but no response was received
            errorsStatus.sendingOrderError = true;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorsStatus.networkError = true;
          }
          this.setState({
            errors: errorsStatus
          });
        });


    }
  }

  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    return isValid;
  }

  getValidity = (file, errorsStatus) => {
    const allowedExtensions = ["image/png", "image/jpeg", "image/jpg", ".doc", ".docx", "application/pdf"];
    const isRightFormat = allowedExtensions.includes(file.type);
    if (!isRightFormat) {
      errorsStatus.fileExstensionError = true;
    }
    const isRightSize = file.size > 0 && file.size / 1024 / 1024 < MaxSize;
    if (!isRightSize) {
      errorsStatus.fileSizeError = true;
    }
    return isRightFormat && isRightSize;
  }


  handleFileChange = (event) => {
    event.preventDefault();

    const oldFiles = { ...this.state.files };
    const newFiles = [];
    let filesLenght = 0;
    if (this.state.files != null) {
      filesLenght = this.state.files.length;
      for (let i = 0; i < filesLenght; i++) {

        newFiles.push(oldFiles[i]);

      }
    }

    let errorsStatus = { ...this.state.errors };
    let i = 0;
    while (i < event.target.files.length && i + filesLenght < MaxFiles) {

      if (this.getValidity(event.target.files[i], errorsStatus)) {

        newFiles.push(event.target.files[i]);
      }
      i++;
    }
    if (i + filesLenght === MaxFiles) {
      errorsStatus.maxFilesError = true;
    }

    this.setState({
      files: newFiles,
      errors: errorsStatus
    });
    event.target.value = '';
  };

  handleClick = () => {
    this.hiddenFileInput.current.click();
  };

  handleDeleteItem(id) {


    if (this.state.files.length > 1) {
      const oldFiles = { ...this.state.files };

      const newFiles = [];
      for (let i = 0; i < this.state.files.length; i++) {

        if (i !== id) {

          newFiles.push(oldFiles[i]);
        }
      }
      this.setState({
        files: newFiles
      })
    }
    else {
      this.setState({
        files: null
      })
    }

  };
  inputChangedHandler = (event, inputIdentifier) => {
    const updatedOrderForm = {
      ...this.state.orderForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
  }

  purchaseDoneHandler = () => {
    this.setState({ purchasing: false });
    this.reset();
  }
  errorModalCancelHandler = () => {
    const updatedErrorsStatus = {
      ...this.state.errors
    };
    for (let key in this.state.errors) {
      updatedErrorsStatus[key] = false;
    }
    this.setState({ errors: updatedErrorsStatus });
  }

  executeScrollToSpinner = () => {
    this.scrollSpinnerRef.current.scrollIntoView();
  };

  render() {
    let fileInput = (
      <div>
        <input type="file"
          multiple
          ref={this.hiddenFileInput}
          onChange={this.handleFileChange}
          style={{ display: 'none' }}
          accept="image/*,application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />

        {!this.state.files || this.state.files.length === 0 ?
          <button type="button" className={classes.UploadButton} onClick={this.handleClick}>
            <p className={classes.UploadButtonText}>JOINDRE VOTRE LISTE</p>
          </button>
          : <button type="button" className={classes.UploadButtonAdd} onClick={this.handleClick}>
            <p className={classes.UploadButtonAddContent}>+</p>
          </button>}



      </div>

    );





    let fileSummary = (<></>);
    const filesArray = [];
    if (this.state.files) {

      for (let i = 0; i < this.state.files.length; i++) {
        filesArray.push({
          id: i,
          file: this.state.files[i]
        });
      }

      fileSummary = (
        <ul className={classes.fileList}>
          {filesArray.map(filesElement => (

            <li key={filesElement.id} className={classes.fileItem}>
              {imagesFormats.includes(filesElement.file.type) ?
                <img className={classes.FilesSummaryElementImage} src={URL.createObjectURL(filesElement.file)} alt={filesElement.file.name} />
                : <FontAwesomeIcon className={classes.icon} icon={faFileAlt} />}
              <p className={classes.FilesSummaryElementName}>{filesElement.file.name}</p>
              <div className={classes.actions}>
                <div className={classes.loading}></div>
                {
                  <FontAwesomeIcon className={classes.icon} icon={faTrash}
                    onClick={(event) => this.handleDeleteItem(filesElement.id)} />
                }
              </div>

            </li>



          ))}

        </ul>
      );
    }
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    let orderContact = (
      <Aux>
        {formElementsArray.map(formElement => (
          <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangedHandler(event, formElement.id)} />
        ))}
      </Aux>

    );

    let form = (<></>);
    if (!this.state.loading) {
      form = (
        <form onSubmit={this.orderHandler}>


          {orderContact}
          {fileInput}
          {fileSummary}
          <Button btnType="Success" disabled={!(this.state.formIsValid && (this.state.files != null))}>Envoyer</Button>
        </form>
      );
    }
    else {

      form = (
        <div>
          <Spinner />
          <h2 style={{ color: '#42A7C0' }}>{this.state.loadingPercentage}%</h2>
        </div>



      );
      this.executeScrollToSpinner();
    }



    return (
      <div>
        <Modal show={this.state.errors.maxFilesError || this.state.errors.fileSizeError || this.state.errors.fileExstensionError || this.state.errors.networkError || this.state.errors.whatsappPhoneNumberError || this.state.errors.sendingOrderError}
          modalClosed={this.errorModalCancelHandler}>

          <div>
            {this.state.errors.networkError ? <p style={{ color: 'red' }}>Erreur de réseau, veuillez réessayer <br />Si le problème persiste, prière de nous contacter</p> : <p></p>}
            {this.state.errors.sendingOrderError ? <p style={{ color: 'red' }}>Erreur dans la commande, veuillez réessayer <br />Si le problème persiste, prière de nous contacter</p> : <p></p>}
            {this.state.errors.whatsappPhoneNumberError ? <p style={{ color: 'red' }}>Numero de téléphone invalide <br />Veuillez saisir un numero Whatsapp valide</p> : <p></p>}
            {this.state.errors.maxFilesError ? <p style={{ color: 'red' }}>Vous ne pouvez pas charger plus de {MaxFiles} fichiers</p> : <p></p>}
            {this.state.errors.fileSizeError ? <p style={{ color: 'red' }}>Vous ne pouvez pas charger des fichiers vides ou de plus de {MaxSize}Mb </p> : <p></p>}
            {this.state.errors.fileExstensionError ? <p style={{ color: 'red' }}>Seuls fichiers suivants sont acceptés : png, jpeg, pdf</p> : <p></p>}
          </div>

          <div className={classes.ModalButton} >
            <Button btnType="Danger" clicked={this.errorModalCancelHandler}>OK</Button>
          </div>
        </Modal>

        <Modal show={this.state.purchasing}
          modalClosed={this.purchaseDoneHandler} >

          {/* <p>Commande effectuée avec succès ! <br /> */}
          <p className={classes.ModalTitleSuccess}>Commande effectuée ! <br /> </p>
          <p>Merci pour votre confiance <br />
            Vous allez recevoir un devis dans les plus brefs délais

          </p>
          <div className={classes.ModalButton} >
            <Button btnType="Success" clicked={this.purchaseDoneHandler}>OK</Button>
          </div>
        </Modal>

        {/*<h1 ref={this.scrollSpinnerRef} className={classes.Title}>LIVRAISON DE VOS FOURNITURES SCOLAIRES </h1>*/}
        {/* <h2 className={classes.Title2}>à CASABLANCA ET PERIPHERIES</h2> */}
        <h1 ref={this.scrollSpinnerRef} className={classes.Title}>Nous avons arrêté de prendre des commandes pour cette année</h1>
        <h2 className={classes.Title2}>Merci pour votre confiance</h2>

        <div className={classes.Form}>

          {form}

        </div>
      </div>

    );
  }
}

export default SuppliesListUpload;