import React, { Component } from 'react';
import {BrowserRouter, useParams } from 'react-router-dom';
import {Route, Routes} from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Home from './containers/Home/Home';
import Contact from './containers/Contact/Contact';
import Quotation from './containers/Quotation/Quotation';
import Guarantee from './containers/Guarantee/Guarantee';
import Invoice from './containers/Invoice/Invoice';
import Aux from './hoc/Auxil';
import { Helmet, HelmetProvider } from "react-helmet-async";


const QuotationWrapper = () => {
  const { orderId } = useParams();
  return <Quotation orderId={orderId} />
}

const GuaranteeWrapper = () => {
  const { orderId } = useParams();
  return <Guarantee orderId={orderId} />
}

const InvoiceWrapper = () => {
  const { orderId } = useParams();
  return <Invoice orderId={orderId} />
}

class App extends Component {
  render () {
    return (
      <HelmetProvider>
      <BrowserRouter>
        <Aux>
          <Layout>      
            <Routes>
              <Route  path='/' element={<Home sandbox={false}/>} />
              <Route path='/test' element={<Home sandbox={true} />} />
              <Route  path='/contact' element={<Contact/>} />
              <Route  path='/devis/:orderId' element={<QuotationWrapper sandbox={false}/>} />
              <Route  path='/guarantee/:orderId' element={<GuaranteeWrapper sandbox={false}/>} />
              <Route  path='/facture/:orderId' element={<InvoiceWrapper sandbox={false}/>} />
              <Route  path='/test/devis/:orderId' element={<QuotationWrapper sandbox={true}/>} />
            </Routes>
          </Layout>     
        </Aux>  
      </BrowserRouter>
      
      </HelmetProvider>
    );
  }
}

export default App;
