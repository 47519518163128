import React from 'react';
import LivrairieLogo from '../../assets/images/LivrairieLogoText.png';

const styles = {
  invoiceContainer: {
    padding: '50px 10px 0px 20px',
    border: '1px solid #ccc',
    margin: '10px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  companyInfo: {
    textAlign: 'left',
  },
  invoiceTopRight: {
    marginTop: '50px',
    textAlign: 'right',
  },
    customerInfo: {
      margin: '10px',
      padding: '5px 30px',
    border: '3px solid #ccc',
    },
  logo: {
    width: '250px',
    height: 'auto',
  },
  invoiceTable: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  invoiceTableTh: {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
    backgroundColor: '#42A7C0',
    color: 'white',
  },
  invoiceTableTd: {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
  },
  summaryTable: {
    float: 'right',
    border: '1px solid #ccc',
    padding: '10px',
  },
  summaryTableTd: {
    padding: '5px',
  },
  summaryTitle: {
    fontWeight: 'bold',
  },
  totalAmount: {
    fontWeight: 'bold',
  },
};

class InvoiceTemplate extends React.Component {
  render() {
    const { invoiceInfo } = this.props;

    return (
      <div style={styles.invoiceContainer}>
        <div style={styles.header}>
          <div style={styles.companyInfo}>
            <img style={styles.logo} src={LivrairieLogo} alt="LivrairieLogo" />
            <div>Lot Nait N°85</div>
            <div>26202/Deroua</div>
            <div>+212 6 54 95 09 70</div>
            <div>Contact@livrairie.ma</div>
            <div>www.livrairie.ma</div>
          </div>
          <div style={styles.invoiceTopRight}>
            {/* <div>Facture: {invoiceInfo.invoiceNumber}</div> */}
            <div style={styles.customerInfo}>
              <div>{invoiceInfo.customerName}</div>
              <div>{invoiceInfo.orderAdress}</div>
              <div>{invoiceInfo.orderCity}</div>
            </div>
            
          </div>
        </div>
        <table style={styles.invoiceTable}>
          <thead>
            <tr>
              <th style={styles.invoiceTableTh}>#</th>
              <th style={styles.invoiceTableTh}>Item</th>
              <th style={styles.invoiceTableTh}>Qte</th>
              <th style={styles.invoiceTableTh}>Prix</th>
              <th style={styles.invoiceTableTh}>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoiceInfo.studentOrderInvoiceItems.map((item, index) => (
              <tr key={index}>
                <td style={styles.invoiceTableTd}>{index + 1}</td>
                <td style={styles.invoiceTableTd}>{item.itemName}</td>
                <td style={styles.invoiceTableTd}>{item.quantity}</td>
                <td style={styles.invoiceTableTd}>{item.price.toFixed(2)} MAD</td>
                <td style={styles.invoiceTableTd}>{item.total.toFixed(2)} MAD</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table style={styles.summaryTable}>
          <tbody>
            <tr>
              <td style={styles.summaryTableTd} className={styles.summaryTitle}>Number of Articles:</td>
              <td style={styles.summaryTableTd}>{invoiceInfo.studentOrderInvoiceItems.length}</td>
            </tr>
            <tr>
              <td style={styles.summaryTableTd} className={styles.summaryTitle}>Sous-Total:</td>
              <td style={styles.summaryTableTd}>{invoiceInfo.total.toFixed(2)} MAD</td>
            </tr>
            <tr>
              <td style={styles.summaryTableTd} className={styles.summaryTitle}>Livraison:</td>
              <td style={styles.summaryTableTd}>{invoiceInfo.deliveryFee.toFixed(2)} MAD</td>
            </tr>
            <tr>
              <td style={{ ...styles.summaryTableTd, ...styles.summaryTitle, ...styles.totalAmount }}>TOTAL:</td>
              <td style={{ ...styles.summaryTableTd, ...styles.totalAmount }}>{(invoiceInfo.total + invoiceInfo.deliveryFee).toFixed(2)} MAD</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default InvoiceTemplate;

