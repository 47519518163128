export const loadEnvVariables = async () => {
  try {
    const response = await fetch('/env.js');
    if (response.ok) {
      const script = await response.text();
      const scriptElement = document.createElement('script');
      scriptElement.text = script;
      document.head.appendChild(scriptElement);
    } else {
      window.env = {
        REACT_APP_BACKEND_PRODUCTION_API_URL: process.env.REACT_APP_BACKEND_PRODUCTION_API_URL || 'http://localhost:8081/api/',
        REACT_APP_BACKEND_STAGING_API_URL: process.env.REACT_APP_BACKEND_STAGING_API_URL || 'http://localhost:8081/api/',
      };
      // window.env = {
      //   REACT_APP_BACKEND_PRODUCTION_API_URL: process.env.REACT_APP_BACKEND_PRODUCTION_API_URL || 'https://livrairie-2024-back-e1e6d25fb1f6.herokuapp.com/api/',
      //   REACT_APP_BACKEND_STAGING_API_URL: process.env.REACT_APP_BACKEND_STAGING_API_URL || 'https://livrairie-2024-back-e1e6d25fb1f6.herokuapp.com/api/',
      // };
    }
  } catch (error) {
    console.error('Error loading environment variables:', error);
    window.env = {
      REACT_APP_BACKEND_PRODUCTION_API_URL: process.env.REACT_APP_BACKEND_PRODUCTION_API_URL || 'http://localhost:8081/api/',
      REACT_APP_BACKEND_STAGING_API_URL: process.env.REACT_APP_BACKEND_STAGING_API_URL || 'http://localhost:8081/api/',
    };
    // window.env = {
    //   REACT_APP_BACKEND_PRODUCTION_API_URL: process.env.REACT_APP_BACKEND_PRODUCTION_API_URL || 'https://livrairie-2024-back-e1e6d25fb1f6.herokuapp.com/api/',
    //   REACT_APP_BACKEND_STAGING_API_URL: process.env.REACT_APP_BACKEND_STAGING_API_URL || 'https://livrairie-2024-back-e1e6d25fb1f6.herokuapp.com/api/',
    // };
  }
};
