import React from 'react';
import classes from './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

const navigationItems = (props) => (
    
    <ul className={classes.NavigationItems}>
        <div className={classes.InfoItems}><i><FontAwesomeIcon  icon={faPhone} /></i><a  href="tel:+212654950970">212654950970</a></div>
        
        <NavigationItem link="/" exact="true">Accueil</NavigationItem>
        <NavigationItem link="/contact" >Nous contacter</NavigationItem>
    </ul>
    
);

export default navigationItems;