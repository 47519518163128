import React, {Component} from 'react';
import LivrairieLogo from '../../assets/images/LivrairieLogoText.png';


const styles = {
  invoiceContainer: {
    padding: '50px 10px 0px 20px',
    border: '1px solid #ccc',
    margin: '10px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  companyInfo: {
    textAlign: 'left',
  },
  invoiceTopRight: {
    marginTop: '50px',
    textAlign: 'right',
  },
    customerInfo: {
      margin: '10px',
      padding: '5px 30px',
    border: '3px solid #ccc',
    },
  logo: {
    width: '250px',
    height: 'auto',
  },
  invoiceTable: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  invoiceTableTh: {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
    backgroundColor: '#42A7C0',
    color: 'white',
  },
  invoiceTableTd: {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'left',
  },
  summaryTable: {
    float: 'right',
    border: '1px solid #ccc',
    padding: '10px',
  },
  summaryTableTd: {
    padding: '5px',
  },
  summaryTitle: {
    fontWeight: 'bold',
  },
  totalAmount: {
    fontWeight: 'bold',
  },
};

class InvoiceSummaryTemplate extends Component {
  render() {
    const { invoices } = this.props;

    // Define styles
    

    const totalArticles = invoices.reduce((sum, invoice) => sum + invoice.studentOrderInvoiceItems.length, 0);
    const totalPrice = invoices.reduce((sum, invoice) => sum + invoice.total + invoice.deliveryFee, 0);

    return (
      <div style={styles.invoiceContainer}>
        <div style={styles.header}>
          <div style={styles.companyInfo}>
            <img style={styles.logo} src={LivrairieLogo} alt="LivrairieLogo" />
            <div>Lot Nait N°85</div>
            <div>26202/Deroua</div>
            <div>+212 6 54 95 09 70</div>
            <div>Contact@livrairie.ma</div>
            <div>www.livrairie.ma</div>
          </div>
          <div style={styles.invoiceTopRight}>
            <div>Récapitulatif</div>
            <div style={styles.customerInfo}>
              <div>{invoices[0].customerName}</div>
              <div>{invoices[0].orderAdress}</div>
              <div>{invoices[0].orderCity}</div>
            </div>
            
          </div>
        </div>
        <table style={styles.invoiceTable}>
          <thead>
            <tr>
              <th style={styles.invoiceTableTh}>Eleve</th>
              <th style={styles.invoiceTableTh}>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, index) => (
              <tr key={invoice.id}>
                <td style={styles.invoiceTableTd}>{index+1}</td>
                <td style={styles.invoiceTableTd}>{(invoice.total+invoice.deliveryFee).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table style={styles.summaryTable}>
          <tbody>
            <tr>
              <td style={styles.summaryTableTd} className={styles.summaryTitle}>Nombre de commandes:</td>
              <td style={styles.summaryTableTd}>{invoices.length}</td>
            </tr>
            <tr>
              <td style={{ ...styles.summaryTableTd, ...styles.summaryTitle, ...styles.totalAmount }}>TOTAL:</td>
              <td style={{ ...styles.summaryTableTd, ...styles.totalAmount }}>{totalPrice.toFixed(2)} MAD</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
export default InvoiceSummaryTemplate;
