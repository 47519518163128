import React, { Component } from 'react';
import classes from './Description.css';
import Aux from '../../hoc/Auxil'


class Description extends Component {
    render() {
        return (
            <Aux>
                <div className={classes.Description}>
                    <h2 className={classes.Title}>COMMENT CA MARCHE ?</h2>
                    <div className={classes.Etape}>
                        <div className={classes.Icon}>
                            <div className={classes.hexagon1}></div>
                            <div className={classes.hexagon2}></div>
                            <div className={classes.hexagon3}></div>
                        </div>
                        <p className={classes.Text}> Prenez en photo votre/vos liste(s) de fournitures scolaires<br/></p>
                    </div>
                    <div className={classes.Etape}>
                        <div className={classes.Icon}>
                            <div className={classes.hexagon1}></div>
                            <div className={classes.hexagon2}></div>
                            <div className={classes.hexagon3}></div>
                        </div>
                        <p className={classes.Text}> Remplissez votre nom et votre numéro de téléphone<br/></p>
                    </div>
                    <div className={classes.Etape}>
                        <div className={classes.Icon}>
                            <div className={classes.hexagon1}></div>
                            <div className={classes.hexagon2}></div>
                            <div className={classes.hexagon3}></div>
                        </div>
                        <p className={classes.Text}> Cliquez sur joindre votre liste et sélectionnez la/les liste(s)<br/></p>
                    </div>
                    <div className={classes.Etape}>
                        <div className={classes.Icon}>
                            <div className={classes.hexagon1}></div>
                            <div className={classes.hexagon2}></div>
                            <div className={classes.hexagon3}></div>
                        </div>
                        <p className={classes.Text}> Cliquez sur envoyer<br/><br/></p>
                    </div>
                       <p className={classes.Text}>
                        N.B: Si vous avez plus d'un enfant, merci de joindre toutes les listes en même temps. 
                    </p>
                    

                </div>
                <div className={classes.Shapes}>
                    <div className={classes.Circle}></div>
                    <div className={classes.Trapezoid}></div>
                </div>
              
            </Aux>


        );
    }
}

export default Description;