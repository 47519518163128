import React, { Component } from 'react';
import classes from './Home.css';
import SuppliesListUpload from '../SuppliesListExplorer/SuppliesListUpload';
import Gamin1Image from '../../assets/images/Gamin.png';
import Gamin2Image from '../../assets/images/Gamin2.jpg';
import Aux from '../../hoc/Auxil'
import Promotion from './Promotion';
import Features from './Features';
import Description from './Description';
import { Helmet, HelmetProvider } from "react-helmet-async";

class Home extends Component {
    render() {
        const currentYear = new Date().getFullYear();
        const pageTitle = `Fournitures Scolaires ${currentYear} - Prenez en photo votre liste et on s'occupe du reste`;

        return (
            <Aux>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta
                        name="description"
                        content="Nous livrons toutes vos fournitures scolaires au même prix que celui de la librairie. Prenez en photo votre liste de fournitures scolaires, et vous allez recevoir un devis."
                    />;
                </Helmet>
                <div className={classes.Section1}>
                    <div className={classes.Form}>
                        {/*this div was added to say that we do not receive orders anymore*/}
                        {/* <div className={classes.TheEnd}>
                            <h1>Nous avons arrêté de recevoir des commandes pour cette année</h1>
                            <h1>Merci pour votre confiance !</h1>
                        </div> */}
                        {/* uncomment this line to return back to the version you worked with during livrairie2022 */}
                        <SuppliesListUpload sandbox={this.props.sandbox}/>
                        <Promotion />
                    </div>
                    <div className={classes.Image1}>
                        <img className={classes.Gamin1} src={Gamin1Image} />
                    </div>


                </div>
                <div className={classes.Section2}>
                    <Description />
                </div>
                <div className={classes.Section3}>

                    <img className={classes.Gamin2} src={Gamin2Image} />
                    <p className={classes.Section3Title}>POURQUOI LIVRAIRIE.MA ?</p>
                    <Features />


                </div>

            </Aux>


        );
    }
}

export default Home;